import { Container, Row, Col } from "react-bootstrap";
import React from "react";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
function TeamMember({ name, bio, interests, tagline, image_name }) {
    return (
        <>
            <Col md={8} className="home-about-description">
                <h1 style={{ fontSize: "2.6em" }}>
                    <span className="purple"> {name}</span>
                </h1>
                <p className="home-about-body">
                    {bio}
                </p>
                <p className="purple home-about-body">
                    {interests}
                </p>
                <p className="home-about-body">
                    <blockquote style={{ color: "rgb(155 126 172)" }} className="blockquote mb-0">
                        {tagline}
                    </blockquote>
                </p>
            </Col>
            <Col md={4} className="myAvtar">
                <Tilt>
                    <img src={image_name} style={{ width: 'auto', height: '200px', borderRadius: '50px' }} className="img-fluid" alt="avatar" />
                </Tilt>
            </Col>
        </>
    );
}
export default TeamMember;