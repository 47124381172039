import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import mario_img from './imgs/mario.jpg'
import francesco_img from './imgs/francesco.png'
import pellegrino_img from './imgs/pellegrino.png'
import francesca_img from './imgs/francesca.png'
import gennaro_img from './imgs/gennaro.jpg'
import TeamMember from "./TeamMember";
import sayna_img from './imgs/sayna.jpg'
import roberto_img from './imgs/roberto.jpg'

function ResumeNew() {


  return (
    <div>
      <Container fluid className="project-section">
        <Particle />
        <Container>
          <Row>
            <TeamMember
              name={"Pellegrino Casoria"}
              bio={"Cybersecurity expert working in Accenture from 9+ years, I act as focal point for Emerging Trends and Innovation topics for Detection"}
              tagline={'"If" is the mark of failures. In life you grew up "despite".'}
              interests={'Cybersecurity, Generative AI, AI /ML, Videogames'}
              image_name={pellegrino_img}
            />
          </Row>
          <Row>
            <hr class="rounded" />
          </Row>
          <Row>
            <TeamMember
              name={"Mario Varlese"}
              bio={'Mario Varlese earned a Master’s degree with honors in Information Technology and Electrical Engineering in 2019 from the University of Naples ”Federico II,” Italy.Currently, he is pursuing a Ph.D. degree in Information Technology and Electrical Engineering at the same University. He has previously worked as a Computer Engineer for Deloitte, Enel and Customs and Monopolies Agency where he gained experience in secure software development, cloud computing, and virtual environments. His primary research interests fall in the area of cybersecurity and the potential applications of AI within this field'}
              tagline={'There is a driving force more powerful than steam, electricity and nuclear power: the will.'}
              interests={'Cybersecurity, AI /ML, Martial Arts, Calistenics, Meditation'}
              image_name={mario_img}
            />
          </Row>
          <Row>
            <hr class="rounded" />
          </Row>
          <Row>
            <TeamMember
              name={"Francesco Cerasuolo"}
              bio={'Ph.D. student in DIETI department at the University of Napoli Federico II, currently engaged in research in the realms of network traffic analysis, as well as machine and deep learning.'}
              tagline={'Problem-solving strategist, turning challenges into opportunities.'}
              interests={'Cybersecurity, AI/ML, Network Traffic Analysis'}
              image_name={francesco_img}
            />
          </Row>
          <Row>
            <hr class="rounded" />
          </Row>
          <Row>
            <TeamMember
              name={"Roberto D'Isanto"}
              bio={'Ph.D. student at DIETI department at the University of Napoli Federico II, currently focused on research in cyber security.'}
              tagline={'I have a dream: having a dream (cit)'}
              interests={'Cybersecurity, drones, 3d printing'}
              image_name={roberto_img}
            />
          </Row>
          <Row>
            <hr class="rounded" />
          </Row>
          <Row>
            <TeamMember
              name={"Gennaro Esposito Mocerino"}
              bio={'Ph.D. student in DIETI department at the University of Napoli Federico II and high school computer science teacher. I’m working in the field of cybersecurity to understand human behavior and on the development of predictive AI models to improve the quality of human life.'}
              tagline={'Stay hungry, stay foolish'}
              interests={'Cybersecurity, AI/ML, Health'}
              image_name={gennaro_img}

            />
          </Row>
          <Row>
            <hr class="rounded" />
          </Row>
          <Row>
            <TeamMember
              name={"Francesca Pagano"}
              bio={'Ph.D student in DIETI department at the University of Napoli Federico II, I am currently working at the Prisma Lab in the Aerial Robotics research group. My research interest is the control of multi-robot systems in agriculture.'}
              tagline={'There is nothing permanent except change'}
              interests={'Robotics, control, drones'}
              image_name={francesca_img}

            />          </Row>
          <Row>
            <hr class="rounded" />
          </Row>
          <Row>
            <TeamMember
              name={"Sayna Rotbei"}
              bio={'Ph.D. student in DIETI department at the University of Napoli Federico II. I am working on developing the predictive AI models in order to improve human life quality'}
              tagline={'AI for health'}
              interests={'AI, ML, DL Health'}
              image_name={sayna_img}

            />          </Row>
          <Row>
            <hr class="rounded" />
          </Row>

        </Container>
      </Container>
    </div>
  );
}

export default ResumeNew;
