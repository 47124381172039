import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col className="home-about-description">
            <h3 style={{ fontSize: "2.6em" }}>
              Our <span className="purple">Mission </span>
            </h3>
            <p style={{ padding: 50, textAlign: "left" }} className="home-about-body">
              We aim to develop cutting-edge AI technologies that proactively detect, prevent, and mitigate cyber threats. Our focus is on creating robust, adaptive systems that continuously evolve to counter emerging security challenges.            </p>
          </Col>
        </Row>
        <Row>
          <Col className="home-about-description">
            <h3 style={{ fontSize: "2.6em" }}>
              Our <span className="purple">Vision </span>
            </h3>
            <p style={{ padding: 50, textAlign: "center" }} className="home-about-body">
              To redefine cybersecurity through innovative AI-driven solutions, ensuring a safer digital world for all.
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="home-about-description">
            <h3 style={{ fontSize: "2.6em" }}>
              Our <span className="purple">Values </span>
            </h3>
            <p style={{ padding: 50, textAlign: "center" }}>
              <span style={{ padding: 50, textAlign: "left" }}><span className="purple">Innovation:</span> Embrace creativity and novel approaches to address cybersecurity concerns.</span><br />
              <span style={{ padding: 50, textAlign: "left" }}><span className="purple">Integrity:</span> Uphold ethical practices and transparency in all endeavors.</span><br />
              <span style={{ padding: 50, textAlign: "left" }}><span className="purple">Collaboration:</span> Foster teamwork and partnerships for impactful solutions.</span><br />
              <span style={{ padding: 50, textAlign: "left" }}><span className="purple">Excellence:</span> Pursue the highest standards of quality in research and development.</span><br />
            </p>
          </Col>
        </Row>


      </Container>
    </Container>
  );
}
export default Home2;
