import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import phishing from './imgs/advanced-phishing-simulation.jpeg'
import malware from './imgs/sintetic-malware-development.jpeg'
import threat from './imgs/threat-intelligence.jpg'
import cryptography from './imgs/advanced-cryptography.jpeg'
import network from './imgs/neuronal-network-analysis.jpeg'
import patterns from './imgs/cyber-attack-patterns.png'
function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Our Recent <strong className="purple">Projects </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects we have worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={patterns}
              isBlog={false}
              title="Adversarial Pattern Generation"
              description="This project involves leveraging generative AI models to simulate and predict new cyber attack patterns, including zero-day attacks. The aim is to develop proactive defense solutions against evolving threats."
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={malware}
              isBlog={false}
              title="Synthetic Malware Development"
              description="Utilizing generative AI to create synthetic malware for testing internal cybersecurity defenses. The project focuses on developing advanced countermeasures to detect and mitigate these threats effectively."
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={phishing}
              isBlog={false}
              title="Advanced Phishing Simulation"
              description="Using generative AI to generate and simulate sophisticated phishing campaigns. This project aims to identify and educate staff about evolving phishing attack methods, strengthening overall security awareness"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={network}
              isBlog={false}
              title="Neural Network Analysis and Security"
              description="Employing generative AI to identify potential vulnerabilities in neural networks used for cybersecurity. The project ensures the integrity and security of AI models themselves."
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={cryptography}
              isBlog={false}
              title="Advanced Cryptography Tool Development"
              description="Leveraging generative AI to enhance and develop novel advanced encryption algorithms. The objective is to bolster data and communication security by innovating in encryption methodologies."
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={threat}
              isBlog={false}
              title="Threat Intelligence Synthesis"
              description="This project involves utilizing generative AI to synthesize threat intelligence reports. By analyzing vast datasets, the AI generates comprehensive and contextualized reports on emerging threats, aiding in proactive threat mitigation and strategic decision-making for cybersecurity defenses."
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
