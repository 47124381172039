import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>

            <span className="purple">CyberGen AI Innovations </span> is a pioneering research entity at the forefront of
            leveraging <span className="purple"> generative AI for cybersecurity solutions.</span>
            <br />
            Our team comprises experts in AI, cybersecurity, and innovative technology, united by a passion for creating advanced defense mechanisms against evolving digital threats.
            <br />
            <br />
            With a blend of expertise, creativity, and a commitment to excellence, we strive to revolutionize the cybersecurity landscape.
          </p>


          <p style={{ color: "rgb(155 126 172)" }}>
            "Cybersecurity is not just about protecting machines, it's about safeguarding society's digital future."{" "}
          </p>
          <footer className="blockquote-footer"> Satya Nadella</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
